<template>
  <div class="container valentine-border" id="container">
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-1.png"
      class="img1"
    />
    <img mode="widthFix" :src="data[0].path" class="img2" />
    <div class="one">
      <span :style="getStyle(data[1])">{{ data[1].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-4.png"
      class="img4"
    />
    <div mode="widthFix" class="img5AndImg6 changeHeight">
      <img mode="widthFix" :src="data[2].path" class="img5" />
      <img mode="widthFix" :src="data[3].path" class="img6" />
    </div>
    <div class="two">
      <div class="text">
        <span :style="getStyle(data[4])">{{ data[4].content }}</span>
      </div>
      <img mode="widthFix" src="@/assets/images/valentine/qrj-7.png" />
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-8.png"
      class="img8"
    />
    <img mode="widthFix" :src="data[5].path" class="img9" />
    <div class="img10AndImg11">
      <img mode="widthFix" :src="data[6].path" class="img10" />
      <img
        mode="widthFix"
        src="@/assets/images/valentine/qrj-11.png"
        class="img11"
      />
    </div>
    <div class="three">
      <span :style="getStyle(data[7])">{{ data[7].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-12.png"
      class="img12"
    />
    <img mode="widthFix" :src="data[8].path" class="img13" />
    <div class="four">
      <span :style="getStyle(data[9])">{{ data[9].content }}</span>
      <img mode="widthFix" src="@/assets/images/valentine/qrj-14.png" />
    </div>
    <img mode="widthFix" :src="data[10].path" class="img15And16" />
    <img mode="widthFix" :src="data[11].path" class="img15And16" />
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-17.png"
      class="img17"
    />
    <div class="five">
      <span :style="getStyle(data[12])">{{ data[12].content }}</span>
      <br />
      <span :style="getStyle(data[13])">{{ data[13].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-18.png"
      class="img18"
    />
    <img mode="widthFix" :src="data[14].path" class="img19" />
    <img mode="widthFix" :src="data[15].path" class="img20" />
    <div class="six">
      <span :style="getStyle(data[16])">{{ data[16].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-21.png"
      class="img21"
    />
    <div class="img22And23">
      <img mode="widthFix" :src="data[17].path" class="img22" />
      <img
        mode="widthFix"
        src="@/assets/images/valentine/qrj-23.png"
        class="img23"
      />
    </div>
    <div class="seven">
      <span :style="getStyle(data[18])" style="margin-bottom: 20%">{{
        data[18].content
      }}</span>
      <img mode="widthFix" src="@/assets/images/valentine/qrj-24.png" />
      <span :style="getStyle(data[19])">{{ data[19].content }}</span>
    </div>
    <img
      mode="widthFix"
      src="@/assets/images/valentine/qrj-25.png"
      class="img25"
    />
  </div>
</template>

<script>
import scroll from "@/mixins/scroll";
import valentineData from "@/assets/data/valentine";
export default {
  mixins: [scroll],
  name: "Valentine",
  props: {
    list: {
      type: Array,
    },
    preview: {
      type: Boolean,
    },
  },
  data() {
    return {
      data: JSON.parse(JSON.stringify(valentineData)),
    };
  },
  methods: {
    getStyle(data) {
      let percent =
        this.$route.name == "display" ||
        this.preview ||
        this.$route.name == "finished"
          ? 1
          : 0.6;
      return {
        fontFamily: data.fontFamily,
        fontSize: data.fontSize * percent + "px",
        color: data.fontColor,
        lineHeight: data.fontLineHeight * percent + "px",
        letterSpacing: data.fontSpacing * percent + "px",
        whiteSpace: "pre-wrap",
      };
    },
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        if (this.list) {
          this.data = this.list;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: #fff;
  overflow-y: auto;
  padding-top: 30px;
}
span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 32px;
}
img {
  display: block;
}
.img1 {
  width: 82%;
  margin: 0 auto;
}
.img2 {
  width: 70%;
  border: 18px solid #fff;
  border-top-left-radius: 800px;
  border-top-right-radius: 800px;
  background-color: pink;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin: 20px auto 15vw;
}
.img4 {
  width: 50.5%;
  margin: 15px auto;
}
.img5AndImg6 {
  position: relative;
  width: 63.3%;
  padding: 30% 0;
  margin: 120px auto;
  border: 2px solid #a01d22;
}
.img5 {
  position: absolute;
  width: 95%;
  border: 8px solid #fff;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
  left: -17%;
  top: -38.5%;
}
.img6 {
  position: absolute;
  width: 90.5%;
  border: 8px solid #fff;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
  right: -11.5%;
  bottom: -35%;
}
.two {
  display: flex;
  width: 84%;
  margin: 20px auto;
  align-items: flex-end;
}
.two .text {
  width: 63.3%;
  flex: 1;
}
.two .text span {
  text-align: left;
}
.two img {
  width: 30%;
}
.img8 {
  width: 50.5%;
  margin: 40px auto;
}
.img9 {
  width: 86%;
  margin: 10px auto;
  border: 9px solid #fff;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
}
.img10AndImg11 {
  width: 90%;
  display: flex;
  margin: 20px auto;
}
.img10 {
  width: 50%;
  border: 8px solid #fff;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
}
.img11 {
  width: 50%;
}
.three {
  width: 85%;
  margin: 30px auto;
}
.img12 {
  width: 79.5%;
  margin: 20px auto;
}
.img13 {
  width: 75.6%;
  margin: 20px auto;
  box-shadow: -20px -20px 0 0 rgba(255, 237, 219, 1);
  transform: translateX(4%);
}
.four {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80%;
  margin: 20px auto;
}
.four span {
  text-align: end;
}
.four img {
  width: 46%;
  margin-top: -8%;
}
.img15And16 {
  width: 82.6%;
  border: 8px solid #fff;
  margin: 20px auto;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
}
.img17 {
  width: 8.5%;
  margin: 25px auto;
}
.img18 {
  width: 67%;
  margin: 15px auto;
}
.img19 {
  width: 76.5%;
  border: 8px solid #fff;
  box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
  margin-left: 8%;
}
.img20 {
  width: 76.5%;
  border: 8px solid #fff;
  box-shadow: 1px 5px 13px 0 rgba(0, 0, 0, 0.25);
  margin: 35px 8% 35px auto;
}
.img21 {
  width: 50.5%;
  margin: 25px auto;
}
.img22And23 {
  position: relative;
  width: 100%;
  .img22 {
    width: 70%;
    border: 15px solid #fff;
    border-top-left-radius: 800px;
    border-top-right-radius: 800px;
    box-shadow: 1px 5px 13px 0px rgba(0, 0, 0, 0.25);
    margin: 40px auto;
  }
  .img23 {
    position: absolute;
    width: 55%;
    bottom: 6%;
    left: 22.5%;
  }
}
.seven {
  position: relative;
  width: 100%;
}
.seven img {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img25 {
  width: 67.6%;
  margin: 20px auto;
}
</style>